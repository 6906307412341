@charset "utf-8";
// Import a Google Font
@import url('https://fonts.googleapis.com/css?family=Nunito:400,500,600,700,900');
@import url('https://fonts.googleapis.com/css?family=Ubuntu:400,500,600,700,900');

// Slider

$color-react: #61dafb;
$color-white: rgb(255, 255, 255);
$color-black: #222;
$scheme-invert: $color-black;
html, body {
  width: 100%;
  height: 100%; }



.wrapper {
  width: 100%;
  height: 100%;
  position: absolute;
  margin: auto;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  overflow: hidden;

  &,
  .front-image {
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
    background-attachment: fixed;
    opacity: 1; }


  .front-image {
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0; }
  /* will-change: left */


  &._move {
    .front-image {
      transition: left 0.9s cubic-bezier(0, 0, 0.2, 1);
      left: -100%; } } }

// Slider End


// Set your brand colors
$purple: #d0d1cd;
$pink: rgb(69, 66, 61);
$brown: #b24514;
$beige-light: #d0d1cd;
$beige-lighter: #fefefe;

// container-background container-margin

.container-background {
  background-image: url('img/bg-valores.jpg');
  background-repeat: no-repeat;
  background-position: center center;
  background-clip: padding-box;
  background-attachment: fixed;
  background-size: cover;
  padding: 0 !important;

  .overlay {
    background-color: rgba(66, 66, 66, 0.6);
    display: block;
    width: 100%;
    padding: 10vh 5vw; } }

.hero {
  &.has-background {
    position: relative;
    overflow: hidden; }
  &-background {
    position: absolute;
    object-fit: cover;
    object-position: center center;
    width: 100%;
    height: 100%;
    &.is-transparent {
      opacity: 0.6; } } }

.hero-background-home {}


.white-1 {
  color: $beige-lighter !important;
  text-shadow: 0em 1px 1px $purple;
  padding-bottom: 5px;
  position: relative; }

.brown-1 {
  color: $pink !important;
  text-shadow: 0em 1px 1px #666;
  padding-bottom: 5px;
  margin-bottom: 40px;
  position: relative; }

.brown-1:after {
    position: absolute;
    bottom: -10px;
    left: 0;
    right: 0;
    width: 100px;
    height: 2px;
    margin: 0 auto;
    background: #666;
    content: ''; }

.white-1:after {
    position: absolute;
    bottom: -5px;
    left: 0;
    right: 0;
    width: 100px;
    height: 2px;
    margin: 0 auto;
    background: $beige-light;
    content: ''; }

.container-margin {
  margin: 1em 0 !important; }

// Update Bulma's global variables
$family-sans-serif: 'Ubuntu','Nunito','Arial', sans-serif;
$grey-dark: $pink;
$grey-light: $beige-light;
$primary: $purple;
$link: $brown;
$widescreen-enabled: true;
$fullhd-enabled: true;

// Update some of Bulma's component variables
$body-background-color: $beige-lighter;
$footer-background-color: $pink;
$footer-padding: 1rem 0.5rem !default;
$footer-color: $beige-light !default;
$control-border-width: 2px;
$input-border-color: transparent;
$input-shadow: none;

$boxMinHeightSubtitle: 120px;
$boxMinHeightTitle: 35px;
$boxPDisplay: block;


.is-value > .title {
  min-height: $boxMinHeightTitle; }

.is-value > .subtitle {
  min-height: $boxMinHeightSubtitle;
  font-size: 1.2em; }

.has-footer-border {
  border-top: 10px solid $brown; }

.has-border {
  border-bottom: 10px solid $brown; }

$widescreen-enabled: true;
$fullhd-enabled: true;

$box-shadow: 0 0.3em 0.5em -0.125em rgba($scheme-invert, 0.1), 0 0px 0 1px rgba($scheme-invert, 0.02);

.hero.is-dark .title {
  color: #fff;
  font-size: 3em;
  text-shadow: 0px 0px 3px #999; }


.title-home {
  font-size: 4em !important;
  text-shadow: 0px 0px 2px #fff; }

.subtitle-home {
  font-weight: 700; }

.subtitle-home>b {
  font-weight: 900; }

// Import only what you need from Bulma
@import '../node_modules/bulma/sass/utilities/_all.sass';
@import '../node_modules/bulma/sass/base/_all.sass';
@import '../node_modules/bulma/sass/components/_all.sass';
@import '../node_modules/bulma/sass/elements/_all.sass';
@import '../node_modules/bulma/sass/form/_all.sass';
@import '../node_modules/bulma/sass/grid/_all.sass';
@import '../node_modules/bulma/sass/layout/_all.sass';
@import '../node_modules/bulma/sass/utilities/_all.sass';
